<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col lg="5" cols="12">
        <v-text-field v-model="view" label="Ansicht" />
      </v-col>
      <v-col lg="3" cols="5">
        <date-time-selector v-model="anfang" label="Anfang" />
      </v-col>
      <v-col lg="3" cols="5">
        <date-time-selector v-model="ende" label="Ende" />
      </v-col>
      <v-col lg="1" cols="2">
        <v-btn block @click="load">Laden</v-btn>
      </v-col>
    </v-row>
    <apexchart type="line" :options="chartoptions" :series="series" height="600" />
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'
import moment from 'moment'

export default {
  name: 'pagelog',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    view: '/e/a5f5057d-8d5c-48b9-a54d-67831460dcf6/tables',
    anfang: moment().subtract(6, 'hours').format('YYYY-MM-DD HH:mm:00'),
    ende: moment().format('YYYY-MM-DD HH:mm:00'),
    PageLog: []
  }),

  computed: {
    filled () {
      if (this.PageLog.length === 0) return []
      const current = new Date(this.PageLog[0].timestamp)
      const last = new Date(this.PageLog[this.PageLog.length - 1].timestamp)

      const data = []
      let i = 0
      while (current.getTime() <= last.getTime()) {
        data.push({ timestamp: new Date(current), count: this.PageLog[i].count })
        current.setMinutes(current.getMinutes() + 1)
        if ((new Date(this.PageLog?.[i + 1]?.timestamp || '2999-01-31'))?.getTime() <= current.getTime()) i++
      }

      return data
    },
    labels () {
      return this.filled.map(l => {
        const date = new Date(l.timestamp)
        if (date.getHours() === 0 && date.getMinutes() === 0) return date.getDate() + '.' + (date.getMonth() + 1) + '. 00:00'
        if (date.getMinutes() === 0) return date.getHours() + ':00'
        if (date.getMinutes() % 15 === 0) return date.getMinutes()
        return ' '
      })
    },
    values () {
      return this.filled.map(l => l.count)
    },
    chartoptions () {
      return {
        stroke: {
          curve: 'stepline',
          width: 1
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: 'Besucher'
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy HH:mm'
          }
        }
      }
    },
    series () {
      return [{
        type: 'line',
        data: this.PageLog.map(l => ([(new Date(l.timestamp)).getTime(), l.count])),
        name: 'Besucher'
      }]
    }
  },

  methods: {
    async load () {
      this.PageLog = (await this.query({
        query: gql`query($view: String!, $start: DateTime!, $end: DateTime!) { PageLog(view: $view, start: $start, end: $end) { timestamp count }}`,
        variables: {
          view: this.view,
          start: this.anfang,
          end: this.ende
        }
      })).PageLog
    }
  }
}
</script>

<style scoped>

</style>
